<template>
    <div>
        <v-card>
            <v-card-title>Lista de docentes</v-card-title>
           <v-simple-table dense>
            <template v-slot:default>
                <thead>
                    <tr>
                    <th class="text-left">
                        Apellidos y Nombres
                    </th>
                    <th class="text-left">
                        DNI
                    </th>
                    <th class="text-left">
                        Correo
                    </th>
                    </tr>
                </thead>
                <tbody>
                    <tr
                    v-for="item in teachers"
                    :key="item.dni"
                    >
                    <td>{{ item.full_name }}</td>
                    <td>{{ item.dni }}</td>
                    <td>{{ item.email }}</td>
                    </tr>
                </tbody>
                </template>
            </v-simple-table>
        </v-card>
    </div>
</template>
<script>
export default {
    name:'TableStudents',
    props:['teachers'],
    data:()=>({
    }),
}
</script>