<template>
    <div>
        <form-create-teacher/>
    </div>
</template>
<script>
import FormCreateTeacher from '../components/users/FormCreateTeacher'
export default {
    components:{
        FormCreateTeacher
    }
}
</script>