<template>
    <div>
        <v-card>
            <v-card-title>Registrar docentes</v-card-title>
            <v-container>
                <v-row justify="space-around">
                    <v-col cols="10" md="3">
                        <v-text-field
                            label="DNI"
                            v-model="dni"
                            @change="get_data_by_dni($event)"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="10" md="3">
                        <v-text-field
                            label="Nombres"
                            v-model="nombres"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="10" md="3">
                        <v-text-field
                            label="Apellido paterno"
                            v-model="paterno"
                        ></v-text-field>
                    </v-col>
                    
                </v-row>
                <v-row justify="space-around">
                    <v-col cols="10" md="3">
                        <v-text-field
                            label="Apellidos materno"
                            v-model="materno"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="10" md="3">
                        <v-text-field
                            label="Correo institucional"
                            v-model="email"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="10" md="3">
                        <v-text-field
                            label="Contraseña"
                            v-model="password"
                        ></v-text-field>
                    </v-col>
                </v-row>
                <v-row justify="space-around">
                    <v-col cols="10" md="8">
                        <v-btn :disabled="!((enable_btn)&&(status_btn))" @click="post_user()"  
                        block
                        color="primary"
                        >Guardar datos</v-btn>
                    </v-col>
                </v-row>
            </v-container>
        </v-card>
        <v-card>
            <v-container>
                <v-row justify="center">
                    <v-col cols="12">
                        <table-teachers :teachers="teachers"/>
                    </v-col>
                </v-row>
            </v-container>
        </v-card>
    </div>
</template>
<script>
import axios from 'axios';
import api from '../../api.config'
import TableTeachers from './TableTeachers'
export default {
    name:'FormCreateTeacher',
    components:{
        TableTeachers
    },
    data:()=>({
        nombres:'',
        paterno:'',
        materno:'',
        dni:'',
        email:'',
        password:'',
        status_btn:true,
        teachers:[]
    }),
    computed:{
        enable_btn(){
            if(this.nombres&&this.paterno&&this.materno&&
               this.dni&&this.email&&this.password)
               {
                   return  true;
               }
               return false;
        }
    },
    methods:{
        get_config(){
            let tok = JSON.parse(localStorage.getItem('token-user'));
            let token = tok['token-user']
            const config = {headers: {'Content-Type': 'application/json','Authorization':'Token '+token}}
            return config;
        },
        post_user(){
            this.status_btn = false
            let dataUser = {
                        email:this.email,
                        user_name:this.email,
                        dni:this.dni,
                        is_student:false,
                        is_teacher:true,
                        is_staff:false,
                        is_active:true,
                        password:this.password
                        }
            axios.post(api.API_PATH+'/api/create-user/',dataUser,this.get_config())
            .then((response)=>{
                this.email = ''
                this.password = ''
                this.dni = ''
                let dataTeacher = {
                    name: this.nombres,
                    paterno: this.paterno,
                    materno: this.materno,
                    user: response.data.id
                }
                axios.post(api.API_PATH+'/api/create-teacher/',dataTeacher,this.get_config())
                .then(()=>{
                    this.nombres = ''
                    this.paterno = ''
                    this.materno = ''
                    this.get_teachers()
                    this.status_btn = true
                }).catch((e)=>{console.log(e)})
            }).catch((e)=>{console.log(e)})
        },
        get_teachers(){
            this.teachers = []
            axios.get(api.API_PATH+'/api/get-all-teacher/',this.get_config())
            .then((res)=>{
                let aux = res.data
                aux.forEach(e => {
                    this.teachers.push({
                        'full_name':e.paterno +' '+e.materno+' '+ e.name,
                        'dni':e.user.dni,
                        'email':e.user.email
                    })
                });
                this.teachers.sort(function(a,b){
                    if(a.full_name>b.full_name) return 1;
                    if(a.full_name<b.full_name) return -1;
                    return 0;
                })
            })
        },
        get_data_by_dni(value){
            this.password = 'cc2021_'+this.dni
            //const config = {headers: {'Content-Type': 'application/json','authorization':'Token k4d2956bd531ab61d44f4fa07304b20e13913815'}}
           axios.get('https://dni.optimizeperu.com/api/persons/'+value)
            .then((res)=>{
                this.nombres = res.data.name
                this.paterno = res.data.first_name
                this.materno = res.data.last_name
                this.password = 'cc2021_'+this.dni
                this.email = '@cramerpuno.edu.pe'
            }).catch((e)=>{console.log(e)})
        }
    },
    mounted(){
        this.get_teachers()
    }

}
</script>